.calendar{
	.calendar-wrap{
		text-align: center;
		margin: 0 auto;
		padding: 2em;
		box-shadow: 8px 13px 8px rgba(0, 0, 0, 0.03);
	}
	.calendar-top{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.2em;
		font-size: 1rem;
		.wedding-month{
			font-size: 1.2rem;
			font-weight: 400;
		}
		.wedding-year{
			font-size: 0.9rem;
		}
		.day-of-week{
			text-transform: uppercase;
			display: inline-block;
			margin-right: 5px;
		}
	}
	table{
		width: 100%;
		text-align: center;
		margin: 0 auto;
		td,th{
			text-align: center;
			vertical-align: middle;
		}
		td{
			height: 0;
			&:after, &:before {
				content: '';
				display: block;
				padding-bottom: calc(50% - 0.6em);
			}
		}
		th{
			font-weight: 500;
			text-transform: uppercase;
			font-size: 0.8rem;
			color: $color-l-grey;
			padding: 0.5em 0;
			&:first-of-type{
				color: $color-red;
			}
			&:last-of-type{
				color: $color-blue;
			}
		}
		.current{
			background-color: $color-primary;
			color: $color-white;
			font-weight: 700;
			border-radius: 50%;
		}
	}
}