@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
@import "//cdn.jsdelivr.net/npm/font-kopub@1.0";
@import url("https://fonts.cdnfonts.com/css/crimson-text");
@import url("https://fonts.cdnfonts.com/css/rouge-script");
.invitation-wrap {
  /* http://meyerweb.com/eric/tools/css/reset/ 
     v2.0 | 20110126
     License: none (public domain)
  */
  /* HTML5 display-role reset for older browsers */
}
.invitation-wrap html, .invitation-wrap body, .invitation-wrap div, .invitation-wrap span, .invitation-wrap applet, .invitation-wrap object, .invitation-wrap iframe,
.invitation-wrap h1, .invitation-wrap h2, .invitation-wrap h3, .invitation-wrap h4, .invitation-wrap h5, .invitation-wrap h6, .invitation-wrap p, .invitation-wrap blockquote, .invitation-wrap pre,
.invitation-wrap a, .invitation-wrap abbr, .invitation-wrap acronym, .invitation-wrap address, .invitation-wrap big, .invitation-wrap cite, .invitation-wrap code,
.invitation-wrap del, .invitation-wrap dfn, .invitation-wrap em, .invitation-wrap img, .invitation-wrap ins, .invitation-wrap kbd, .invitation-wrap q, .invitation-wrap s, .invitation-wrap samp,
.invitation-wrap small, .invitation-wrap strike, .invitation-wrap strong, .invitation-wrap sub, .invitation-wrap sup, .invitation-wrap tt, .invitation-wrap var,
.invitation-wrap b, .invitation-wrap u, .invitation-wrap i, .invitation-wrap center,
.invitation-wrap dl, .invitation-wrap dt, .invitation-wrap dd, .invitation-wrap ol, .invitation-wrap ul, .invitation-wrap li,
.invitation-wrap fieldset, .invitation-wrap form, .invitation-wrap label, .invitation-wrap legend,
.invitation-wrap table, .invitation-wrap caption, .invitation-wrap tbody, .invitation-wrap tfoot, .invitation-wrap thead, .invitation-wrap tr, .invitation-wrap th, .invitation-wrap td,
.invitation-wrap article, .invitation-wrap aside, .invitation-wrap canvas, .invitation-wrap details, .invitation-wrap embed,
.invitation-wrap figure, .invitation-wrap figcaption, .invitation-wrap footer, .invitation-wrap header, .invitation-wrap hgroup,
.invitation-wrap menu, .invitation-wrap nav, .invitation-wrap output, .invitation-wrap ruby, .invitation-wrap section, .invitation-wrap summary,
.invitation-wrap time, .invitation-wrap mark, .invitation-wrap audio, .invitation-wrap video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  color: inherit;
  vertical-align: baseline;
  text-decoration: none;
}
.invitation-wrap article, .invitation-wrap aside, .invitation-wrap details, .invitation-wrap figcaption, .invitation-wrap figure,
.invitation-wrap footer, .invitation-wrap header, .invitation-wrap hgroup, .invitation-wrap menu, .invitation-wrap nav, .invitation-wrap section {
  display: block;
}
.invitation-wrap body {
  line-height: 1;
}
.invitation-wrap a:hover {
  color: inherit;
}
.invitation-wrap ol, .invitation-wrap ul, .invitation-wrap li {
  list-style: none;
}
.invitation-wrap blockquote, .invitation-wrap q {
  quotes: none;
}
.invitation-wrap blockquote:before, .invitation-wrap blockquote:after,
.invitation-wrap q:before, .invitation-wrap q:after {
  content: "";
  content: none;
}
.invitation-wrap table {
  border-collapse: collapse;
  border-spacing: 0;
}
.invitation-wrap a:hover {
  text-decoration: none;
}
.invitation-wrap * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
.invitation-wrap * {
  box-sizing: border-box;
  transition: all 0.3s;
}
.invitation-wrap html {
  font-size: 20px;
}
.invitation-wrap section {
  font-size: 1rem;
  line-height: 1.2;
}
.invitation-wrap section:not(.visual) {
  width: 55%;
  margin: 0 auto;
}
.invitation-wrap body, .invitation-wrap a {
  font-family: "Noto Sans KR", sans-serif;
  color: #222;
  word-break: keep-all;
}
.invitation-wrap section {
  padding: 3em 0;
}
.invitation-wrap section h2 {
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  color: #9D9E91;
  font-weight: 500;
}
.invitation-wrap section .wedding-date {
  margin-top: 1.5rem;
  display: block;
}
.invitation-wrap .invitation-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
}
.invitation-wrap .visual {
  position: relative;
  text-align: center;
}
.invitation-wrap .visual .wedding-tit {
  display: block;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
}
.invitation-wrap .visual .wedding-character {
  text-transform: uppercase;
}
.invitation-wrap .visual {
  position: relative;
}
.invitation-wrap .visual img {
  object-fit: cover;
}
.invitation-wrap .visual .visual-content .wedding-date span {
  display: block;
}
.invitation-wrap .visual .visual-content .wedding-date span + span {
  margin-top: 5px;
}
.invitation-wrap .visual .visual-content .connect-sign {
  text-transform: lowercase;
}
.invitation-wrap .visual.visualType01 {
  padding-bottom: 100vh;
}
.invitation-wrap .visual.visualType01 .visual-img {
  position: absolute;
  left: 50%;
  top: 0;
  width: 95%;
  height: 100vh;
  transform: translateX(-50%);
}
.invitation-wrap .visual.visualType01 .visual-img img {
  width: 100%;
  height: 100%;
}
.invitation-wrap .visual.visualType01 .visual-content {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 36px;
  transform: translateX(-50%);
  z-index: 5;
}
.invitation-wrap .visual.visualType01 .visual-content .wedding-tit {
  color: #fff;
}
.invitation-wrap .visual.visualType01 .visual-content .wedding-character {
  font-weight: 400;
  padding: 1em 0;
  font-size: 2.5rem;
}
.invitation-wrap .visual.visualType01 .visual-content .wedding-character strong {
  display: block;
  color: #7C7D71;
  font-weight: 400;
}
.invitation-wrap .visual.visualType01 .visual-content .wedding-character .connect-sign {
  font-size: 1.8rem;
  display: block;
  color: #7C7D71;
}
.invitation-wrap .visual.visualType01 .visual-content .wedding-date {
  text-align: center;
  font-size: 0.9rem;
  color: #fff;
}
.invitation-wrap .visual.visualType01 .visual-content .wedding-date span {
  display: block;
  margin-bottom: 0.5em;
}
.invitation-wrap .visual.visualType02 .visual-img {
  padding-top: 5vw;
  height: 100vh;
}
.invitation-wrap .visual.visualType02 .visual-img img {
  width: 100%;
  height: 100%;
}
.invitation-wrap .visual.visualType02 .visual-content {
  position: absolute;
  left: 20px;
  bottom: 120px;
  z-index: 5;
  text-align: left;
  color: #fff;
}
.invitation-wrap .visual.visualType02 .visual-content .wedding-character {
  font-size: 2rem;
}
.invitation-wrap .visual.visualType02 .visual-content .wedding-character .connect-sign {
  margin: 0 5px;
}
.invitation-wrap .visual.visualType02 .wedding-main-date {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 16vw;
  min-width: 285px;
  height: 16vw;
  min-height: 285px;
  background-color: #9D9E91;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
.invitation-wrap .visual.visualType02 .wedding-main-date::after {
  content: "";
  width: 90%;
  height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
}
.invitation-wrap .visual.visualType02 .wedding-main-date small {
  font-size: 1.5rem;
  margin-bottom: 5px;
}
.invitation-wrap .visual.visualType02 .wedding-main-date strong {
  font-size: 3.5rem;
}
.invitation-wrap .intro {
  text-align: center;
}
.invitation-wrap .intro p {
  font-size: 1rem;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5;
  color: #666;
}
.invitation-wrap .intro .wedding-character {
  text-align: center;
  display: inline-block;
  margin-top: 3em;
  text-transform: uppercase;
  color: #666;
  font-size: 1.2rem;
  font-weight: 500;
}
.invitation-wrap .intro .wedding-character::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #666;
  display: block;
}
.invitation-wrap .intro .wedding-character strong {
  font-weight: inherit;
}
.invitation-wrap .intro .wedding-character .connect-sign {
  margin: 0 0.2em;
}
.invitation-wrap .calendar .calendar-wrap {
  text-align: center;
  margin: 0 auto;
  padding: 2em;
  box-shadow: 8px 13px 8px rgba(0, 0, 0, 0.03);
}
.invitation-wrap .calendar .calendar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2em;
  font-size: 1rem;
}
.invitation-wrap .calendar .calendar-top .wedding-month {
  font-size: 1.2rem;
  font-weight: 400;
}
.invitation-wrap .calendar .calendar-top .wedding-year {
  font-size: 0.9rem;
}
.invitation-wrap .calendar .calendar-top .day-of-week {
  text-transform: uppercase;
  display: inline-block;
  margin-right: 5px;
}
.invitation-wrap .calendar table {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.invitation-wrap .calendar table td, .invitation-wrap .calendar table th {
  text-align: center;
  vertical-align: middle;
}
.invitation-wrap .calendar table td {
  height: 0;
}
.invitation-wrap .calendar table td:after, .invitation-wrap .calendar table td:before {
  content: "";
  display: block;
  padding-bottom: calc(50% - 0.6em);
}
.invitation-wrap .calendar table th {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #666;
  padding: 0.5em 0;
}
.invitation-wrap .calendar table th:first-of-type {
  color: #ae1e1e;
}
.invitation-wrap .calendar table th:last-of-type {
  color: #351ec7;
}
.invitation-wrap .calendar table .current {
  background-color: #9D9E91;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
}
.invitation-wrap .rsvp form .input-wrap {
  display: block;
  font-size: 1rem;
  margin-bottom: 2em;
}
.invitation-wrap .rsvp form .input-wrap label {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 1em;
}
.invitation-wrap .rsvp form .input-wrap input[type=text] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  font-size: 1rem;
  padding: 1em 0;
  outline: none;
}
.invitation-wrap .rsvp form .input-wrap input[type=text]::placeholder {
  color: #aaa;
}
.invitation-wrap .rsvp form .input-wrap textarea {
  border-color: #eee;
  width: 100%;
  padding: 1em;
  font-size: 1rem;
  font-family: inherit;
  height: 200px;
  color: #444;
  outline: none;
}
.invitation-wrap .rsvp form .radio-wrap {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-right: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.invitation-wrap .rsvp form .radio-wrap label {
  display: inline-block;
  margin-bottom: 0;
  line-height: 20px;
}
.invitation-wrap .rsvp form .radio-wrap .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border: 1px solid #666;
  border-radius: 50%;
  display: block;
}
.invitation-wrap .rsvp form .radio-wrap .checkmark::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #666;
  transition: 0.3s all;
  opacity: 0;
}
.invitation-wrap .rsvp form .radio-wrap input[type=radio] {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 5;
}
.invitation-wrap .rsvp form .radio-wrap input[type=radio]:checked ~ .checkmark::after {
  opacity: 1;
}
.invitation-wrap .rsvp form button {
  border: none;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.6em;
  width: 100%;
  outline: none;
  border: 1px solid #9D9E91;
  transition: all 0.3s;
  cursor: pointer;
}
.invitation-wrap .gallery .swiperType01 {
  margin-bottom: 10px;
}
.invitation-wrap .gallery .swiperType01 img {
  max-height: 350px !important;
  width: 100%;
}
.invitation-wrap .gallery .swiperType01 .swiperType01-thumb img {
  filter: grayscale(100%);
}
.invitation-wrap .contact .contact-list {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.invitation-wrap .contact .contact-list .character-img img {
  max-height: 70px;
  border-radius: 50%;
}
.invitation-wrap .contact .contact-list .contact-infos {
  margin: 0 50px 0 20px;
}
.invitation-wrap .contact .contact-list .contact-infos .tel {
  font-size: 1.4rem;
  color: #666;
}
.invitation-wrap .contact .contact-list .contact-infos .tel:hover {
  color: #9D9E91;
}
.invitation-wrap .contact .contact-list .contact-infos .email {
  font-size: 0.8rem;
  color: #aaa;
  text-decoration: underline;
}
.invitation-wrap .contact .contact-list .contact-infos .email:hover {
  color: #666;
}
.invitation-wrap .contact .contact-list .contact-infos a {
  color: inherit;
}
.invitation-wrap .contact .contact-list .tel-img {
  width: 45px;
  height: 45px;
  background-color: #9D9E91;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.invitation-wrap .contact .contact-list .tel-img:hover {
  transform: translateY(-55%);
}
.invitation-wrap #header {
  background-color: #fff;
  box-shadow: none;
  position: sticky;
  left: 0;
  top: 0;
}
.invitation-wrap #header .header-content {
  align-items: center;
  justify-content: space-between;
  height: 90px;
}
.invitation-wrap #header .logo {
  color: #222;
  display: flex;
  align-items: center;
}
.invitation-wrap #header a {
  white-space: nowrap;
  color: #222;
  font-size: 16px;
  font-weight: 400;
}
.invitation-wrap #header .gnb-right,
.invitation-wrap #header .gnb-left,
.invitation-wrap #header .header-left {
  display: flex;
  align-items: center;
}
.invitation-wrap #header li {
  padding: 0;
  margin-left: 15px;
}
.invitation-wrap #header a[class*=btn-] {
  box-shadow: none;
  color: #fff;
}
.invitation-wrap #header a[class*=btn-].btn-primary {
  background-color: #FF8689;
  border: 1px solid #FF8689;
}
.invitation-wrap #header a[class*=btn-].btn-primary:hover {
  background-color: #fff;
  color: #FF8689;
}
.invitation-wrap #header a[class*=btn-].btn-secondary {
  background-color: #666;
  border: 1px solid #666;
}
.invitation-wrap #header a[class*=btn-].btn-secondary:hover {
  background-color: #fff;
  color: #666;
}
.invitation-wrap #Landing #visual {
  width: 100%;
  height: 700px;
  background-color: #ccc;
}
.invitation-wrap #Landing #visual li {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 650px) {
  .invitation-wrap html {
    font-size: 16px;
  }
  .invitation-wrap section:not(.visual) {
    width: 60%;
    max-width: 350px;
  }
}
@media (max-width: 500px) {
  .invitation-wrap html {
    font-size: 14px;
  }
  .invitation-wrap section:not(.visual) {
    width: calc(100% - 40px);
  }
  .invitation-wrap .visual.visualType02 .visual-img {
    padding-top: 15vw;
  }
  .invitation-wrap .visual.visualType02 .wedding-main-date {
    min-width: 250px;
    min-height: 250px;
  }
}

.invitation-wrap.invitation-test-wrap {
  font-family: "KoPub Batang";
  max-width: 720px;
  margin: 0 auto;
  background: url("../assets/img/bg-han.jpg") no-repeat 100%/cover;
  line-height: 1.8;
}
.invitation-wrap.invitation-test-wrap section {
  margin-bottom: 150px;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 100%;
}
.invitation-wrap.invitation-test-wrap section:last-child {
  margin-bottom: 0;
  padding-bottom: 150px;
}
.invitation-wrap.invitation-test-wrap section .section-tit,
.invitation-wrap.invitation-test-wrap section .calendar-month {
  color: #C9736E;
  font-weight: 700;
  font-size: 26px;
}
.invitation-wrap.invitation-test-wrap section .title-devider {
  margin-bottom: 30px;
  display: block;
}
.invitation-wrap.invitation-test-wrap section .title-devider figure {
  text-align: center;
}
.invitation-wrap.invitation-test-wrap section .title-devider img {
  max-height: 25px;
}
.invitation-wrap.invitation-test-wrap section .custom-button {
  background-color: #C9736E;
  border: none;
  padding: 15px 0;
  color: #fff;
  font-family: "KoPub Batang";
  font-size: 20px;
  width: 100%;
  cursor: pointer;
}
.invitation-wrap.invitation-test-wrap section .custom-button a {
  color: inherit;
  font: inherit;
}
.invitation-wrap.invitation-test-wrap .visualType04 {
  padding: 0;
  height: 100vh;
  background: url(../assets/img/hansung/hansung-main.jpg) no-repeat top/cover;
}
.invitation-wrap.invitation-test-wrap .visualType04 .visual-content {
  position: relative;
  padding: 0 40px;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-date-tit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: #7B7574;
  letter-spacing: 0.09px;
  font-weight: 400;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-date-tit .slash {
  display: flex;
  margin: 10px 0;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-date-tit .slash img {
  max-height: 16px;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-opening {
  font-size: 20px;
  color: #7B7574;
  display: flex;
  align-items: flex-end;
  line-height: 1.5;
  padding: 100px 0;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-opening span + span {
  margin-left: 10px;
}
.invitation-wrap.invitation-test-wrap .visualType04 .visual-img {
  position: relative;
  display: flex;
}
.invitation-wrap.invitation-test-wrap .visualType04 .visual-img img {
  width: 100%;
  max-width: 720px;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-details {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  width: 100%;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-details p {
  margin: 0;
  line-height: 1.8;
  font-size: 20px;
  color: #fff;
}
.invitation-wrap.invitation-test-wrap .visualType04 .wedding-details p.wedding-name {
  margin-bottom: 10px;
  font-weight: 700;
}
.invitation-wrap.invitation-test-wrap .introType02 {
  width: 100% !important;
  max-width: 100%;
  padding: 0 40px;
  position: relative;
}
.invitation-wrap.invitation-test-wrap .introType02::before {
  content: "";
  width: 152px;
  height: 178px;
  background: url("../assets/img/visuan-han-deco3.png") no-repeat center/cover;
  display: inline-block;
  position: absolute;
  top: -20px;
  left: 0;
}
.invitation-wrap.invitation-test-wrap .introType02 p {
  font-size: 20px;
  line-height: 1.8;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character {
  width: 100%;
  text-align: center;
  margin-top: 80px;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character::After {
  display: none;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap {
  letter-spacing: 2px;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap + .character-wrap {
  margin-top: 20px;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap .parents,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap .character,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap .parents,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap .character {
  font-size: 20px;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap .parents,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap .parents {
  margin-right: 5px;
}
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap .character,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap .character {
  margin-left: 30px;
}
.invitation-wrap.invitation-test-wrap .calendarType02 {
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 0 120px;
}
.invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap {
  box-shadow: none;
  display: flex;
  padding: 0;
}
.invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .calendar-month {
  margin-right: 40px;
  margin-top: 10px;
}
.invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap th, .invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap td {
  font-size: 20px;
}
.invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .current {
  position: relative;
  background-color: transparent;
  color: #C9736E;
}
.invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .current .circle {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  border: 1px solid #C9736E;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .current .time {
  position: absolute;
  left: 50%;
  bottom: -30px;
  transform: translateX(-50%);
  font-size: 16px;
  white-space: nowrap;
}
.invitation-wrap.invitation-test-wrap .calendarType02::after {
  content: "";
  width: 200px;
  height: 236px;
  background: url("../assets/img/visuan-han-deco4.png") no-repeat center/cover;
  display: inline-block;
  position: absolute;
  top: -100px;
  right: 0;
}
.invitation-wrap.invitation-test-wrap .galleryType02 {
  width: 100%;
  padding-bottom: 0;
}
.invitation-wrap.invitation-test-wrap .contactType02 {
  width: 65%;
}
.invitation-wrap.invitation-test-wrap .contactType02 .character-img img {
  max-height: 80px;
}
.invitation-wrap.invitation-test-wrap .contactType02 .contact-info .name {
  font-size: 22px;
  color: #7B7574;
  letter-spacing: 2px;
}
.invitation-wrap.invitation-test-wrap .contactType02 .contact-info .name .position {
  font-size: 16px;
}
.invitation-wrap.invitation-test-wrap .contactType02 .contact-info.tel {
  margin-top: 5px;
}
.invitation-wrap.invitation-test-wrap .contactType02 .contact-info.tel a {
  font-size: 18px;
  color: #444;
  font-family: "KoPub Batang";
  letter-spacing: 2px;
}
.invitation-wrap.invitation-test-wrap .contactType02 .tel-img {
  background-color: transparent;
  border: 1px solid #888;
  width: auto;
  height: auto;
  padding: 10px 15px;
  border-radius: 40px;
}
.invitation-wrap.invitation-test-wrap .contactType02 .tel-img a {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #888;
  font-family: "KoPub Batang";
}
.invitation-wrap.invitation-test-wrap .contactType02 .tel-img img {
  max-height: 24px;
  margin-right: 15px;
}
.invitation-wrap.invitation-test-wrap .locationType02 {
  width: 65%;
  margin-bottom: 0;
  padding-bottom: 150px;
  text-align: center;
}
.invitation-wrap.invitation-test-wrap .locationType02 dt {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.invitation-wrap.invitation-test-wrap .locationType02 dd {
  font-size: 16px;
  color: #7B7574;
  margin-bottom: 5px;
}
.invitation-wrap.invitation-test-wrap .locationType02 .map {
  padding: 30px 0;
}
.invitation-wrap.invitation-test-wrap .locationType02 .map img {
  width: 100%;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap {
  padding-left: 120px;
  padding-right: 120px;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap .carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap .thumbs-wrapper {
  margin-left: 0;
  margin-right: 0;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap .thumbs-wrapper:hover {
  border-color: #888 !important;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap .thumb {
  padding: 0;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap .thumb.selected {
  border-color: #C9736E !important;
}
.invitation-wrap.invitation-test-wrap .carousel-wrap .thumb:hover {
  border-color: #888 !important;
}
.invitation-wrap .sound {
  width: 50px;
  height: 50px;
  border: 1px solid #aaa;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 5;
  background-color: #F8F8F7;
  cursor: pointer;
}
.invitation-wrap .sound img {
  max-height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (orientation: landscape) {
  .invitation-wrap.invitation-test-wrap .visualType04 {
    height: 1360px;
  }
}
@media (max-width: 600px) {
  .invitation-wrap.invitation-test-wrap section {
    margin-bottom: 100px;
    width: 100% !important;
  }
  .invitation-wrap.invitation-test-wrap section.locationType02 {
    padding-bottom: 100px;
    margin-bottom: 0;
  }
  .invitation-wrap.invitation-test-wrap section:not(.visual, .galleryType02) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .invitation-wrap.invitation-test-wrap section .section-tit {
    font-size: 22px;
  }
  .invitation-wrap.invitation-test-wrap section .title-devider img {
    max-height: 16px;
  }
  .invitation-wrap.invitation-test-wrap section .custom-button {
    font-size: 16px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 {
    max-height: 720px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .visual-content::after {
    width: 128px;
    height: 152px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .wedding-date-tit {
    font-size: 40px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .wedding-opening {
    padding: 40px 0 30px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .wedding-opening span {
    font-size: 16px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .wedding-opening span + span {
    margin-left: 6px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .visual-img::before {
    width: 104px;
    height: 122px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .wedding-details {
    margin-top: 45px;
  }
  .invitation-wrap.invitation-test-wrap .visualType04 .wedding-details p {
    font-size: 16px;
  }
  .invitation-wrap.invitation-test-wrap .introType02 p {
    font-size: 16px;
  }
  .invitation-wrap.invitation-test-wrap .introType02::before {
    width: 104px;
    height: 122px;
  }
  .invitation-wrap.invitation-test-wrap .introType02 .wedding-character {
    margin-top: 60px;
  }
  .invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap .parents,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .character-wrap .character,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap .parents,
.invitation-wrap.invitation-test-wrap .introType02 .wedding-character .parent-wrap .character {
    font-size: 16px;
  }
  .invitation-wrap.invitation-test-wrap .calendarType02::after {
    width: 130px;
    height: 150px;
  }
  .invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .calendar-month {
    font-size: 22px;
    margin-right: 10px;
  }
  .invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap th, .invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap td {
    font-size: 16px;
  }
  .invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .current .circle {
    width: 100%;
    height: 100%;
    max-width: 42px;
    max-height: 42px;
  }
  .invitation-wrap.invitation-test-wrap .calendarType02 .calendar-wrap .current .time {
    font-size: 14px;
    bottom: -20px;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .tel-img {
    width: 45px;
    height: 45px;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .tel-img img {
    max-height: 17px;
    margin-right: 0;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .tel-img a {
    font-size: 14px;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .contact-info .name {
    font-size: 18px;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .contact-info .position {
    font-size: 14px;
    letter-spacing: 0;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .character-img img {
    max-height: 65px;
  }
  .invitation-wrap.invitation-test-wrap .contactType02 .mobile-hidden {
    display: none;
  }
  .invitation-wrap.invitation-test-wrap .carousel-wrap {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

