// Font Vairables
$font-primary : "Noto Sans KR", sans-serif;
// $font-primary : "Noto Sans KR", sans-serif;

// Color Variables
$color-primary: #9D9E91;
$color-secondary: #E1E2DE;
$color-black : #222;
$color-d-grey : #444;
$color-l-grey : #666;
$color-border : #eee;
$color-placeholder : #aaa;
$color-white: #fff;
$color-red: #ae1e1e;
$color-blue: #351ec7;

$color-theme-primary: #FF8689;

*{
	box-sizing: border-box;
	transition: all 0.3s;
}
html{
	font-size: 20px;
}
section{
	font-size: 1rem;
	line-height: 1.2;
	&:not(.visual){
		width: 55%;
		margin: 0 auto;
	}
}
body, a{
	font-family: $font-primary;
	color: $color-black;
	word-break: keep-all;
}
section{
	padding: 3em 0;
	h2{
		font-size: 1.4rem;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 1.5em;
		color: $color-primary;
		font-weight: 500;
	}
	.wedding-date{
		margin-top: 1.5rem;
		display: block;
	}
}
.invitation-wrap{
	max-width: 800px;
	margin: 0 auto;
	padding: 0;
}
.visual{
	position: relative;
	text-align: center;
	.wedding-tit{
		display: block;
		text-align: center;
		font-size : 1rem;
		text-transform: uppercase;
	}
	.wedding-character{
		text-transform: uppercase;
	}
}